import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export const useProfileNameAndCodeFromRoute = (): {
  name: string;
  code: string;
} | null => {
  const { parameters } = useRoute();
  return useMemo(() => {
    if (!parameters?.length) return null;
    const [name, code] =
      parameters.length > 1
        ? parameters[1].split("-")
        : parameters[0].split("-");
    return {
      name,
      code,
    };
  }, [parameters]);
};

/*
1. you can pass in profileId
2. if you don't have profileId, but your url like: xxx/profile/{name-code}, you can pass in parseFromUrl=true
3. if you don't have profileId or url, it will read profileId from lastLoggedInIdByGame
*/
const useCurrentD2User = (
  profileId?: string,
  parseFromUrl: boolean = false,
) => {
  const { settings } = useSnapshot(readState);
  const profileFromUrlObj = useProfileNameAndCodeFromRoute();

  const {
    lastLoggedInIdByGame: { destiny2: d2profileId },
    loggedInAccounts: { destiny2: destiny2Accounts },
  } = settings;

  const profile = useMemo(() => {
    if (profileId) {
      return destiny2Accounts[profileId];
    }
    if (parseFromUrl && profileFromUrlObj) {
      return Object.values(destiny2Accounts).find(
        (item) =>
          item.bungieName === profileFromUrlObj.name &&
          String(item.bungieNameCode) === profileFromUrlObj.code,
      );
    }
    // todo fix; bug where logged in but no profileId
    if (d2profileId === null && Object.keys(destiny2Accounts).length === 1) {
      return destiny2Accounts[Object.keys(destiny2Accounts)[0]];
    }
    return destiny2Accounts[d2profileId];
  }, [
    profileId,
    parseFromUrl,
    profileFromUrlObj,
    destiny2Accounts,
    d2profileId,
  ]);
  return profile;
};

export default useCurrentD2User;
